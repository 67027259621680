import styles from "./JobSearch.module.scss"
import useTranslation from "next-translate/useTranslation"
import FilterList from "./FilterList"
import SearchBox from "./SearchBox"
import * as ToggleGroup from "@radix-ui/react-toggle-group"
import ResultList from "./ResultList"
import BookmarkButton from "./BookmarkButton"
import { useEffect, useRef, useState } from "react"
import { ChevronDown, Grid, List, Sliders } from "react-feather"
import * as Select from "@radix-ui/react-select"
import Pagination from "./Pagination"
import Button from "../FormInputs/Button"
import Badge from "../Badge"
import { useDispatch, useSelector } from "react-redux"
import {
  getActiveFilterCount,
  getJobCount,
  searchJobs,
  setSort,
} from "../../store/slices/searchSlice"
import FilterModal from "./FilterModal"
import GoogleMap from "./GoogleMap"
import MapModal from "./MapModal"
import Breadcrumbs from "../Breadcrumbs"
import JobCard from "./JobCard"

function JobSearch({ data = {} }) {
  // console.log("JobSearch", data)
  const dispatch = useDispatch()
  const search = useSelector((state) => state.search)

  const [jobSearchMapModalOpen, setJobSearchMapModalOpen] = useState(false)
  useEffect(() => {
    document.querySelector("header").style.width = jobSearchMapModalOpen
      ? "calc(100% + 15px)"
      : "auto"
  }, [jobSearchMapModalOpen])

  const [currentPage, setCurrentPage] = useState(1)
  useEffect(() => {
    if (jobSearchMapModalOpen) return

    dispatch(
      searchJobs({
        currentPage,
      }),
    )
  }, [currentPage, search.sort])

  useEffect(() => {
    if (jobSearchMapModalOpen) return

    setCurrentPage(1)
    dispatch(
      searchJobs({
        currentPage,
      }),
    )
  }, [search.term, search.location, search.activeFilters])

  const activeFilterCount = useSelector(getActiveFilterCount)
  const jobCount = useSelector(getJobCount)

  const { t } = useTranslation("jobsearch")

  const options = {
    enableMap: data?.enable_map || false,
    preferredResultStyle:
      data?.preferred_result_style?.value?.toLowerCase() || "grid",
    userCanChooseStyle: data?.user_can_choose_style || false,
    sortOptions: [
      {
        id: "relevance",
        name: t("sort.relevance"),
        cond: () => true,
      },
      {
        id: "date",
        name: t("sort.date"),
        cond: () => true,
      },
      {
        id: "distance",
        name: t("sort.distance"),
        cond: () => !!search?.location?.city,
      },
    ],
  }

  const [filterModalOpen, setFilterModalOpen] = useState(false)
  const [resultsDisplayType, setResultsDisplayType] = useState(
    options.preferredResultStyle,
  )

  const resultListScrollRef = useRef(null)
  const scrollToTopOfResults = () => {
    if (!resultListScrollRef.current) return
    resultListScrollRef.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    })
  }

  useEffect(() => {
    if (!search?.location?.city) return
    dispatch(setSort("distance"))
  }, [search?.location?.city])

  return (
    <div className={styles.JobSearch}>
      <SearchBox
        className={styles.searchBox}
        fullWidth={true}
        headline={t("searchbox.headline")}
      />

      {!search.isInitialPageload ? (
        <div className={styles.topContent} ref={resultListScrollRef}>
          <div className={styles.filterButtonWrapper}>
            <Button
              iconLeft={<Sliders />}
              variant={"secondary"}
              onClick={() => {
                setFilterModalOpen(true)
              }}
            >
              Filter
              {activeFilterCount > 0 ? (
                <Badge circle color={"red"}>
                  {activeFilterCount}
                </Badge>
              ) : null}
            </Button>
          </div>
          <div className={styles.breadcrumbsWrapper}>
            <Breadcrumbs />
          </div>
          <div className={styles.bookmarkButtonWrapper}>
            <BookmarkButton showBadge={true} buttonAsStyle={"link"} />
          </div>
          <h2
            className={styles.searchResultMessage}
            dangerouslySetInnerHTML={{
              __html: `
          ${t("results.count", {
            count: jobCount,
          })}
            ${
              jobCount > 0 && search.term
                ? ` ${t("results.term", { term: search.term })}`
                : ""
            }
            ${
              jobCount > 0 && search.location.city
                ? ` ${t("results.location", {
                    location: search.location.city,
                  })}`
                : ""
            }
            ${
              jobCount > 0 && search.location.city && search.location?.range > 0
                ? ` ${t("results.range", { range: search.location?.range })}`
                : ""
            }
          `,
            }}
          />

          {jobCount > 0 ? (
            <div className={styles.resultOptions}>
              <div className={styles.sortWrapper}>
                <span>{t("sort.label")}:</span>
                <Select.Root
                  className={styles.sort}
                  value={search.sort}
                  onValueChange={(value) => {
                    dispatch(setSort(value))
                  }}
                >
                  <Select.Trigger
                    aria-label="Sort By"
                    className={styles.sortTrigger}
                  >
                    <Select.Value />
                    <Select.Icon aria-hidden>
                      <ChevronDown />
                    </Select.Icon>
                  </Select.Trigger>
                  <Select.Content position={"popper"}>
                    <Select.Viewport className={styles.sortViewport}>
                      {options.sortOptions.map((option) => {
                        if (!option.cond()) return null

                        return (
                          <Select.Item
                            key={option.id}
                            value={option.id}
                            className={styles.sortItem}
                          >
                            <Select.ItemText>{option.name}</Select.ItemText>
                          </Select.Item>
                        )
                      })}
                    </Select.Viewport>
                  </Select.Content>
                </Select.Root>
              </div>
              {options?.userCanChooseStyle ? (
                <div className={styles.displayWrapper}>
                  <ToggleGroup.Root
                    className={styles.displayType}
                    type="single"
                    defaultValue={options.preferredResultStyle}
                    aria-label="Display type"
                    onValueChange={setResultsDisplayType}
                  >
                    <ToggleGroup.Item
                      className={styles.toggleButton}
                      value="grid"
                      aria-label="as grid"
                    >
                      <Grid />
                    </ToggleGroup.Item>
                    <ToggleGroup.Item
                      className={styles.toggleButton}
                      value="list"
                      aria-label="as list"
                    >
                      <List />
                    </ToggleGroup.Item>
                  </ToggleGroup.Root>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}

      {jobCount > 0 ? (
        <div className={styles.mainContent}>
          <div>
            {!jobSearchMapModalOpen ? (
              <div className={styles.googleMap}>
                <GoogleMap isStatic />
                <Button
                  variant={"tertiary"}
                  className={styles.googleMapButton}
                  onClick={() => {
                    setJobSearchMapModalOpen(true)
                  }}
                >
                  Karte anzeigen
                </Button>
              </div>
            ) : null}

            <FilterList className={styles.filterList} />
          </div>

          <div className={styles.resultList}>
            <ResultList results={search.results} display={resultsDisplayType} />

            {!search?.isLoading ? (
              <Pagination
                currentPage={currentPage}
                onPageChange={({ selected }) => {
                  setCurrentPage(selected + 1)
                }}
              />
            ) : null}
          </div>
        </div>
      ) : !search.isInitialPageload ? (
        <div className={"container"}>
          <JobCard variant={"initiative"} className={"max-w-2xl"} />
        </div>
      ) : (
        <div className={"container"}>
          <span className={"text-3xl font-bold"}>{t("results.loading")}</span>
        </div>
      )}

      {jobSearchMapModalOpen ? (
        <MapModal
          open={jobSearchMapModalOpen}
          onOpenChange={setJobSearchMapModalOpen}
          setFilterModalOpen={setFilterModalOpen}
        />
      ) : null}

      <FilterModal open={filterModalOpen} onOpenChange={setFilterModalOpen} />
    </div>
  )
}

export default JobSearch
